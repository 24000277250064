import cn from 'classnames';
import React, { useRef } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Col, Row } from '@sravni/react-design-system/lib/Grid';
import { Space } from '@sravni/react-design-system/lib/Space';
import { useIsOTPSended } from '@sravni/react-silent-authentication-form';
import type { FormProps } from '@sravni/react-silent-authentication-form/lib/Form';
import { Form } from '@sravni/react-silent-authentication-form/lib/Form';
import { GetOTPButton } from '@sravni/react-silent-authentication-form/lib/GetOTPButton';
import { OTPInput } from '@sravni/react-silent-authentication-form/lib/OTPInput';
import { PhoneInput } from '@sravni/react-silent-authentication-form/lib/PhoneInput';
import { ServerErrorMessage } from '@sravni/react-silent-authentication-form/lib/ServerErrorMessage';
import { SubmitButton } from '@sravni/react-silent-authentication-form/lib/SubmitButton';
import { useIsMobile } from '@sravni/react-utils';

import { formatPhoneNumber } from '@src/components/AuthDialog/components/AuthSilentForm/utils';
import { authDialogTexts } from '@src/components/AuthDialog/consts';
import { useStickyDialogFooter } from '@src/hooks/useStickyDialog';

import styles from './styles.module.scss';

const { Heading, Text } = Typography;

export const AuthSilentForm: React.FC<FormProps> = (props) => {
    const [isOTPSended] = useIsOTPSended();
    const isMobile = useIsMobile();

    const { firstFreeLoan } = authDialogTexts;
    const phoneRef = useRef<HTMLInputElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);

    useStickyDialogFooter(footerRef.current, true);

    const title = isOTPSended ? 'Введите код из СМС' : firstFreeLoan.title;

    const subTitle = isOTPSended ? (
        <>В течение пары минут на номер {formatPhoneNumber(phoneRef.current?.value)} придёт СМС с кодом подтверждения</>
    ) : (
        firstFreeLoan.subtitle
    );

    return (
        <Form {...props}>
            <div>
                <Heading level={3}>{title}</Heading>
                <Text size={14} className="h-color-D60 h-mt-8">
                    {subTitle}
                </Text>
            </div>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <PhoneInput ref={phoneRef} className="h-mb-12 h-mt-12" />
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <div
                    ref={footerRef}
                    className={cn(styles.footer, {
                        [styles.sticky]: isMobile,
                    })}
                >
                    {isOTPSended ? (
                        <Col span={12}>
                            <Space size={[8, 16]} justify="space-between">
                                <OTPInput />
                                <SubmitButton variant="primary" />
                            </Space>
                        </Col>
                    ) : (
                        <Col span={12}>
                            <GetOTPButton variant="primary">Получить код</GetOTPButton>
                        </Col>
                    )}
                    <Col span={12}>
                        <ServerErrorMessage className="h-mt-16" />
                    </Col>
                </div>
            </Row>
        </Form>
    );
};
