import { useCallback, useEffect } from 'react';

import { notEmptyPredicate } from '@src/helpers/predicates';
import { isIPhone } from '@src/utils/device';

export const useStickyDialogFooter = (ref: HTMLElement | null, enabled = true) => {
    const isAllowedExecute = notEmptyPredicate(ref) && enabled && isIPhone();

    const resizeHandler = useCallback(() => {
        if (typeof ref?.getBoundingClientRect !== 'function') {
            return;
        }

        const rect = ref.getBoundingClientRect();

        if (window.visualViewport!.height + rect.height < window?.innerHeight) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const bottomOffset = window?.innerHeight - window.visualViewport!.height;
            ref.style.bottom = `${bottomOffset}px`;
        } else {
            ref.style.bottom = `${0}px`;
        }
    }, [ref]);

    const scrollHandler = useCallback(() => {
        const bottomOffset = window.innerHeight - window.visualViewport!.height - window.scrollY;
        if (!ref) return;
        ref.style.bottom = `${bottomOffset > 0 ? bottomOffset : 0}px`;
    }, [ref]);

    useEffect(() => {
        if (!isAllowedExecute || !window.visualViewport) return () => {};
        window.visualViewport.addEventListener('resize', resizeHandler);
        window.visualViewport.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [scrollHandler, resizeHandler, isAllowedExecute]);
};
