export function notEmptyPredicate<TValue>(value: TValue | undefined | null): value is TValue {
    if (value == null) {
        return false;
    }
    if (Array.isArray(value) && value.length > 0) {
        return true;
    }
    if (typeof value === 'object') {
        return Object.entries(value).length > 0;
    }

    return true;
}
