import cn from 'classnames';
import { memo } from 'react';
import { useDispatch } from 'react-redux';

import { Dialog } from '@sravni/react-design-system';
import type { DialogCloseEvent } from '@sravni/react-design-system/dist/types/components/Dialog/types/Dialog.types';
import { useIsMobile } from '@sravni/react-utils';

import { AuthForm } from '@src/components/AuthDialog/components/AuthForm';
import { closeAuthDialog } from '@src/reducers/authDialog';

import styles from './styles.module.scss';

interface IProps {
    visible: boolean;
    className?: string;
    onClose: (event: DialogCloseEvent) => void;
}

const AuthDialog = memo(({ visible, className, onClose }: IProps) => {
    const isMobile = useIsMobile();
    const dispatch = useDispatch();

    const handleLogin = () => {
        dispatch(closeAuthDialog());
        window.location.reload();
    };

    return (
        <Dialog
            className={cn(styles.wrapper, className)}
            visible={visible}
            fullscreen={isMobile}
            onClose={onClose}
            closable
        >
            <AuthForm onLogin={handleLogin} />
        </Dialog>
    );
});

export default AuthDialog;
