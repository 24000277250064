import React from 'react';

import { ContextProvider } from '@sravni/react-silent-authentication-form/lib/ContextProvider';
import type { FormProps } from '@sravni/react-silent-authentication-form/lib/Form';

import { AuthSilentForm } from '../AuthSilentForm';

type AuthFormProps = {
    className?: string;
    onLogin: FormProps['onLogin'];
    onError?: FormProps['onError'];
};

const META_INFO = {
    customer: 'mfo',
    productName: 'Витрина МФО',
};

export const AuthForm: React.FC<AuthFormProps> = (props) => (
    <ContextProvider metaInfo={META_INFO}>
        <AuthSilentForm {...props} />
    </ContextProvider>
);
