import type { DialogInitialType } from '@src/@types/authDialog';

export const authDialogTexts: Record<DialogInitialType, { title: string; subtitle: string }> = {
    firstFreeLoan: {
        title: 'Первый займ под 0%',
        subtitle: 'доступен только авторизованным пользователям, для этого введите ваш номер телефона',
    },
    issuanceProbability: {
        title: 'Чтобы увидеть вероятность одобрения авторизуйтесь по номеру',
        subtitle: 'На него мы отправим СМС с кодом подтверждения',
    },
};
